<template>
    <div>
        <b-button-group>
            <b-button
                @click="$store.commit('ISCODE_BTN', true)"
                :variant="isCode ? 'primary' : 'outline-primary'"
                >رموز التفعيل</b-button
            >
            <b-button
                @click="$store.commit('ISCODE_BTN', false)"
                :variant="isCode ? 'outline-primary' : 'primary'"
                >الحزم المتوفرة</b-button
            >
        </b-button-group>
        <component class="mt-2" :is="activeComponent"></component>
    </div>
</template>

<script>
import codeTable from "@admin/activationCode/components/Code.vue";
import packageTable from "@admin/activationCode/components/Package.vue";
import { mapState } from "vuex";
export default {
    components: {
        codeTable,
        packageTable
    },

    computed: {
        ...mapState({
            isCode: ({ activationCode }) => activationCode.isCode,
            activeComponent: ({ activationCode }) => {
                return activationCode.isCode ? codeTable : packageTable;
            }
        })
    },
    methods: {}
};
</script>

<style></style>
