var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ek-table',{attrs:{"items":_vm.resultCodeList,"columns":_vm.codeCols},on:{"delete-selected":_vm.delCode},scopedSlots:_vm._u([{key:"items.startDate",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value ? new Date(value).toLocaleDateString() : "غير مفعل"))])]}},{key:"items.codeStatus",fn:function(ref){
var value = ref.value;
return [_c('StatusBadge',{attrs:{"value":value,"options":_vm.codeStatusType}})]}},{key:"items.sellPointName",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value ? value : "لايوجد"))])]}},{key:"items.endDate",fn:function(ref){
var props = ref.props;
return [_c('span',[_vm._v(_vm._s(_vm.methodDate( props.row.startDate, props.row.packageExpiryPeriod )))])]}},{key:"items.studentName",fn:function(ref){
var value = ref.value;
return [_c('span',[_vm._v(_vm._s(value ? value : "لايوجد"))])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }