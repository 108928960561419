<template>
    <div>
        <ek-table
            :items="resultCodeList"
            :columns="codeCols"
            @delete-selected="delCode"
        >
            <template slot="items.startDate" slot-scope="{ value }">
                <span>{{
                    value ? new Date(value).toLocaleDateString() : "غير مفعل"
                }}</span>
            </template>
            <template slot="items.codeStatus" slot-scope="{ value }">
                <StatusBadge :value="value" :options="codeStatusType" />
            </template>
            <template slot="items.sellPointName" slot-scope="{ value }">
                <span>{{ value ? value : "لايوجد" }}</span>
            </template>

            <template slot="items.endDate" slot-scope="{ props }">
                <!-- new Date(props.row.startDate).getMonth() + 1 + 3 -->
                <span>{{
                    methodDate(
                        props.row.startDate,
                        props.row.packageExpiryPeriod
                    )
                }}</span>
            </template>
            <template slot="items.studentName" slot-scope="{ value }">
                <span>{{ value ? value : "لايوجد" }}</span>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";
export default {
    components: {
        StatusBadge
    },
    computed: {
        ...mapState({
            codeList: ({ activationCode }) => activationCode.codeList,
            codeCols: ({ activationCode }) => activationCode.codeCols,
            codeStatusType: ({ activationCode }) =>
                activationCode.codeStatusType,
            resultCodeList: ({ activationCode }) =>
                activationCode.resultCodeList
        }),

        ...mapGetters(["resultCodeList"])
    },
    methods: {
        methodDate(startDate, expiryPeriod) {
            startDate = new Date();
            startDate.setMonth(startDate.getMonth() + expiryPeriod);
            var date = startDate.toLocaleString().split(",")[0];
            return date;
        },
        delCode(e) {
            this.deleteCodes(e);
        },
        ...mapActions(["getAllCode", "deleteCodes"])
    },
    created() {
        this.getAllCode();
    }
};
</script>

<style></style>
