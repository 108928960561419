<template>
    <div>
        <ek-table
            :items="packageList"
            :columns="columnsPackage"
            @details="detailsPackage"
            @delete-selected="delPackage"
        >
            <template slot="items.dateCreated" slot-scope="{ value }">
                <span>{{ new Date(value).toLocaleDateString() }}</span>
            </template>
            <template slot="items.type" slot-scope="{ value }">
                <span>{{
                    packageStatusType.find(ele => ele.id == value).name
                }}</span>
            </template>
            <template slot="items.isHidden" slot-scope="{ value }">
                <StatusBadge
                    :options="packageStatus"
                    :value="value"
                ></StatusBadge>
            </template>
        </ek-table>
    </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import StatusBadge from "@/app/global/components/StatusBadge.vue";
export default {
    components: {
        StatusBadge
    },
    computed: {
        ...mapState({
            packageList: ({ activationCode }) => activationCode.packageList,
            columnsPackage: ({ activationCode }) =>
                activationCode.columnsPackage,
            packageStatus: ({ activationCode }) => activationCode.packageStatus,
            packageStatusType: ({ activationCode }) =>
                activationCode.packageStatusType
        })
    },
    methods: {
        detailsPackage({ row }) {
            this.$router.push(`/admin/activationCode/package/${row.id}`);
        },
        delPackage(e) {
            this.deletePackages(e);
        },
        ...mapActions(["getAllPackage", "deletePackages"])
    },
    created() {
        this.getAllPackage();
    }
};
</script>

<style></style>
